<template>
  <div>
    <UIForm @form="submitForm"/>
  </div>
</template>

<script>
import UIForm from '@/components/ui/form/form.vue'

export default {
  name: 'Step4',

  components: {
    UIForm,
  },

  methods: {
    submitForm(data) {
      this.$emit("form", data);
    },
  }
}
</script>

<style></style>
