<template>
  <div class="ui-form">
    <form class="ui-form__inputs" @submit.prevent="submitForm">
      <input
        type="text"
        v-model="formData.fullName"
        placeholder="Имя Фамилия"
        class="input-field"
      />
      <p class="ui-form__text">Обязательно введите телефон и почту, либо что-то одно</p>
      
      <input
        type="tel"
        v-model="formData.phone"
        placeholder="+7*"
        :class="{'input-error': validationError}"
        class="input-field"
      />
      <input
        type="email"
        v-model="formData.email"
        placeholder="Рабочий email*"
        :class="{'input-error': validationError}"
        class="input-field"
      />

      <textarea
        v-model="formData.message"
        placeholder="Сроки запуска, бюджет, требования, ваши пожелания ..."
        class="textarea-field"
      ></textarea>

      <div class="file-upload">
        <label>
          <input type="file" @change="handleFileUpload" class="file-input" />
          <span class="file-upload-label">📎 Добавить файл</span>
        </label>
      </div>

      <div class="checkbox-container">
        <input
          type="checkbox"
          v-model="formData.agreed"
          id="agreement"
          required
        />
        <label for="agreement">
          Принимаю условия
          <a href="/conf_politic.pdf" target="_blank">политики конфиденциальности</a>
        </label>
      </div>

      <p v-if="validationError" class="error-message">Введите хотя бы один контакт: телефон или email</p>

      <button type="submit" :disabled="!formData.agreed" class="submit-button">
        НАЧАТЬ ПРОЕКТ
      </button>
    </form>
    <div v-if="showSuccessMessage" class="success-message">
      Форма успешно отправлена!
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIForm',
  data() {
    return {
      formData: {
        fullName: '',
        phone: '',
        email: '',
        message: '',
        file: null,
        agreed: false,
      },
      validationError: false,
      showSuccessMessage: false,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.formData.file = event.target.files[0];
    },
    submitForm() {
      if (!this.formData.phone && !this.formData.email) {
        this.validationError = true;
        return;
      }
      
      this.validationError = false;
      
      const data = new FormData();
      data.append('fullName', this.formData.fullName);
      data.append('phone', this.formData.phone);
      data.append('email', this.formData.email);
      data.append('message', this.formData.message);
      data.append('projectAge', this.$store.getters.getClientForm.projectAge);
      data.append('projectType', this.$store.getters.getClientForm.projectType);
      data.append('time', this.$store.getters.getClientForm.time);

      if (this.formData.file) {
        data.append('file', this.formData.file);
      }

      this.$emit("form", data);

      this.showSuccessMessage = true;
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 3000);

      this.resetForm();
    },
    resetForm() {
      this.formData = {
        fullName: '',
        phone: '',
        email: '',
        message: '',
        file: null,
        agreed: false,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-form {
  max-width: 700px;
  margin: auto;
  padding: 10%;
  background-color: white;
  border-radius: 8px;

  &__text {
    color: gray;
    font-size: 18px;
    font-family: 'Montserrat-Light';
  }
}

.input-field,
.textarea-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 18px;
  font-family: 'Montserrat-Light';
  color: black;
}

.input-error {
  border-color: red !important;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

.textarea-field {
  min-height: 80px;
  resize: vertical;
}

.file-upload {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.file-input {
  display: none;
}

.file-upload-label {
  color: black;
  cursor: pointer;
  font-family: 'Montserrat-Light';
  font-size: 18px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-top: 10px;
  font-family: 'Montserrat-Light';
  color: black;
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-container a {
  color: black;
  text-decoration: none;
}

.checkbox-container a:hover {
  text-decoration: underline;
}

.submit-button {
  width: 100%;
  padding: 15px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 80px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Montserrat-Light';
}

.submit-button:disabled {
  background-color: white;
  border: 1px solid black;
  cursor: not-allowed;
  color: black;
  font-family: 'Montserrat-Light';
}

.submit-button:hover:not(:disabled) {
  background-color: black;
  transform: scale(1.02);
}

.success-message {
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease, fadeOut 0.3s ease 2.7s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  to {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
}

@media screen and (max-width: 760px) {
  .checkbox-container {
    font-size: 12px;
  }
  .ui-form__text {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .ui-form{
    margin-top: 30px;
  }
  .checkbox-container {
    font-size: 12px;
  }
  .ui-form__text {
    display: none;
  }
  .file-upload-label {

    font-size: 12px;
  }
  .error-message {
    display: none;
  }
}
</style>