<template>
  <div class="block-contact">
    <h2
      data-aos="slide-left"
      data-aos-offset="-500"
      data-aos-delay="0"
      data-aos-duration="2000"
      data-aos-easing="ease-in"
      data-aos-once="true"
      class="block-contact__label"
    >
      Как с нами связаться?
    </h2>

    <div class="block-contact__contacts">
      <div
        class="block-contact__contacts-item"
        v-for="(item, index) in getContact()"
        :key="index"
      >
        <p class="block-contact__contacts-item__label">{{ item.label }}:</p>
        <a
          v-if="item.link"
          :href="item.link"
          target="_blank"
          ref="noopeper noreferrer"
          class="block-contact__contacts-item__text"
          >{{ item.text }}</a
        >
        <p v-else class="block-contact__contacts-item__text">
          {{ item.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockContact',

  methods: {
    getContact() {
      return [
        {
          label: 'Телефон',
          text: '+7-(917)-826-09-88',
        },
        {
          label: 'E-mail',
          text: 'letsgo@itvibe.ru',
        },
        {
          label: 'Телеграм канал',
          text: '@itvibenews',
          link: 'https://t.me/itvibenews',
        },
        {
          label: 'Telegram bot для связи',
          text: '@itvibebot',
          link: 'https://t.me/itvibebot',
        },
        {
          label: 'Адрес',
          text: 'Самара, ул Скляренко, д. 26, оф. 3007',
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.block-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  &__label {
    color: white;
    font-size: 96px;
    text-align: right;
    font-family: 'Montserrat-ExtraBold';
    margin: 0;
    padding-top: 3%;
    margin-bottom: 100px;
  }

  &__contacts {
    width: 1200px;

    &-item {
      padding: 10px;
      color: white;
      display: flex;
      flex-direction: row;
      gap: 40px;

      &__label {
        text-decoration: none;
        display: flex;
        color: white;
        font-size: 40px;
        font-family: 'Montserrat-Bold';
        text-align: center;
      }

      &__text {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: white;
        font-size: 39px;
        font-family: 'Montserrat-Light';
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .block-contact {
    margin-bottom: 100px;

    &__label {
      color: white;
      font-size: 80px;
      text-align: center;
      margin-bottom: 80px;
    }

    &__contacts {
      width: 100%;

      &-item {
        padding: 0 3%;

        &__label {
          font-size: 30px;
        }

        &__text {
          font-size: 29px;
        }
      }
    }
  }
}
@media screen and (max-width: 760px) {
  .block-contact {
    margin-bottom: 100px;

    &__label {
      color: white;
      font-size: 50px;
      text-align: center;
      margin-bottom: 80px;
    }

    &__contacts {
      width: 350px;

      &-item {
        flex-direction: column;
        gap: 0;

        &__label {
          font-size: 20px;
          margin-bottom: 0;
        }

        &__text {
          text-decoration: none;
          display: flex;
          align-items: center;
          color: white;
          font-size: 19px;
          font-family: 'Montserrat-Light';
          text-align: center;
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
