import http from "@/api/http.js";

export default class Api {
  //////  GET  //////
  async getBlog() {
    try {
      const {data} = await http.get("/get.php");
      return data;
    } catch (error) {
      console.error("Ошибка получения постов:", error);
      return null;
    }
  }

  //////  POST  //////
  async setForm(form) {
    try {
      const {data} = await http.post("/notify.php", form);
      console.log("Ответ сервера:", data)
      return data;      
    } catch (error) {
      console.error("Ошибка отправки формы:", error)
      return null;
    }

  }
}