<template>
  <div class="blog">
    <BlogContent  :posts="posts"/>
  </div>
</template>

<script>
import BlogContent from '@/components/shared/blog/blog.vue'
import Api from "@/api/api";

const api = new Api();

export default {
  name: 'Blog',

  components: {
    BlogContent,
  },

  data() {
    return {
      posts: [],
    }
  },

  created() {
    window.scrollTo(0, 0);
    this.getPosts();
  },

  methods: {
    async getPosts() {
      this.posts = await api.getBlog();
      // console.log(this.posts)
    }
  }
}
</script>

<style></style>
