import { createStore } from "vuex"

const store = createStore({
  state: {
    clientForm: {
        projectType: '',
        projectAge: '',
        time: '',
    },
  },
  mutations: {
    setType(state, type) {
      state.clientForm.projectType = type;
    },
    setAge(state, age) {
        state.clientForm.projectAge = age;
    },
    setTime(state, time) {
        state.clientForm.time = time;
    },
    resetForm(state) {
        state.clientForm = {
            projectType: '',
            projectAge: '',
            time: '',
        }
    }
  },
  getters: {
    getClientForm: (state) => state.clientForm,
  },
})

export default store