<template>
  <div class="services">
    <ServicesContent @form="submitForm"/>
  </div>
</template>

<script>
import ServicesContent from '@/components/shared/services/services.vue'
import Api from "@/api/api";

const api = new Api();
export default {
  name: 'Services',

  mounted() {
    this.scrollToHash()
  },

  methods: {
    scrollToHash() {
      this.$nextTick(() => {
        if (this.$route.hash) {
          const element = document.querySelector(this.$route.hash)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
    async submitForm(data) {
      await api.setForm(data);
    }
  },

  components: {
    ServicesContent,
  },
}
</script>

<style></style>
