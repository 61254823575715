<template>
  <div class="block-menu">
    <UINav :links="getNav()" />
    <div class="block-menu__close" @click="onOpen">
      <img
        class="block-menu__close-img"
        src="@/assets/img/default/point.svg"
        alt="close"
      />
    </div>
  </div>
</template>

<script>
import UINav from '@/components/ui/nav/nav.vue'

export default {
  name: 'BlockMenu',

  components: {
    UINav,
  },

  methods: {
    getNav() {
      return [
        {
          id: 0,
          label: 'Начать проект',
          link: '/services#constructor',
          active: true
        },
        {
          id: 1,
          label: 'Компания',
          link: '/company',
          active: true
        },
        // {
        //     id: 2,
        //     label: "Портфолио",
        //     link: "/portfolio",
        //     active: false,
        // },
        {
          id: 3,
          label: 'Блог',
          link: '/blog',
          active: false,
        },
        {
          id: 4,
          label: 'Услуги',
          link: '/services',
          active: false,
        },
      ]
    },

    onOpen() {
      this.$emit('sideMenu', true)
    },
  },
}
</script>

<style lang="scss">
.block-menu {
  display: flex;
  flex-direction: row;
  height: 100%;

  &__close {
    display: none;
    &-img {
      width: 25px;
    }
  }
}
@media screen and (max-width: 760px) {
  .block-menu__close {
    display: flex;
  }
}
</style>
