<template>
  <div class="block-blog">
    <div class="block-blog__post" v-for="post in posts" :key="post.id">
      <UICard :background="'white'" :padding="5" :borderRadius="20">
        <UIPost :post="post"></UIPost>
      </UICard>
    </div>
  </div>
</template>

<script>
import UICard from '@/components/ui/card/card.vue'
import UIPost from '@/components/ui/post/post.vue'

export default {
  name: 'BlockBlog',

  components: {
    UICard,
    UIPost,
  },

  props: {
    posts: {
      type: Array,
    }
  },
}
</script>

<style lang="scss">
.block-blog {
  position: relative;
  display: grid;
  z-index: 10;
  grid-template-columns: 1fr;
  padding: 10%;
  gap: 40px;
}
</style>
